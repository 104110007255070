import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Icon } from "../../components/Icon";
import Loader from "../../components/Loader/Loader";
import { qrReadVideo } from "../../redux/actions/video";
import PetalsBlock from "./PetalsBlock/PetalsBlock";


const MainPage = () => {
  const navigate = useNavigate();
  const { dictionaries } = useSelector((state) => state);
  const { currentUser } = useSelector((state) => state.currentUser);
  const { fullList } = useSelector((state) => state);
  const [choose, setChoose] = useState(false);
  let regionIdData = "AA";
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);
  const [dif, setDif] = useState("");
  const [categ, setCateg] = useState("");
  const [sub, setSub] = useState("");
  const [action, setAction] = useState("default");
  const videoType = useParams().type;
  const [type, setType] = useState(videoType?videoType:"official");
  const [className, setClassName] = useState({
    "01": "normal",
    "02": "normal",
    "03": "normal",
    "04": "normal",
    "05": "normal",
    "06": "normal",
  });

  const regionId = useParams().idregion;
  const idgromady = useParams().idgromady;

  const qr = useParams().qr ? true : window.location.href.includes("/qr/");
  const categoryId = useParams().idcategory;

  if (regionId && regionId.length > 2) {
    switch (regionId) {
      case "vinnytsia-region":
        regionIdData = "AB";
        break;
      case "volyn-region":
        regionIdData = "AC";
        break;
      case "donetsk-region":
        regionIdData = "AH";
        break;
      case "dnipropetrovsk-region":
        regionIdData = "AH";
        break;
      case "zhytomyr-region":
        regionIdData = "АМ";
        break;
      case "zakarpattia-region":
        regionIdData = "AO";
        break;
      case "zaporizhzhia-region":
        regionIdData = "AP";
        break;
      case "ivano-frankivsk-region":
        regionIdData = "AT";
        break;
      case "kyiv-region":
        regionIdData = "AI";
        break;
      case "kirovohrad-region":
        regionIdData = "BA";
        break;
      case "luhansk-region":
        regionIdData = "BB";
        break;
      case "lviv-region":
        regionIdData = "BC";
        break;
      case "mykolaiv-region":
        regionIdData = "BE";
        break;
      case "odesa-region":
        regionIdData = "BH";
        break;
      case "poltava-region":
        regionIdData = "BI";
        break;
      case "rivne-region":
        regionIdData = "BK";
        break;
      case "sumy-region":
        regionIdData = "BM";
        break;
      case "ternopil-region":
        regionIdData = "BO";
        break;
      case "kharkiv-region":
        regionIdData = "AX";
        break;
      case "kherson-region":
        regionIdData = "BT";
        break;
      case "khmelnytsk-region":
        regionIdData = "BX";
        break;
      case "cherkasy-region":
        regionIdData = "CA";
        break;
      case "chernihiv-region":
        regionIdData = "CB";
        break;
      case "chernivtsi-region":
        regionIdData = "CE";
        break;
      case "ar-krim":
        regionIdData = "АК";
        break;
      case "dnipropetrovsk-region-new":
        regionIdData = "AE";
        break;
      default:
        regionIdData = "AA";
        break;
    }
  }

  const checkGeolocation = () => {
    if (qr) {
      setAction("qr");
    }
    setReady(true);
    if (!qr && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: +position.coords.latitude.toFixed(7),
            lng: +position.coords.longitude.toFixed(7),
          };

          checkFunction(pos);
          // sessionStorage.setItem('coordinates',JSON.stringify(pos))
          // geocode( {location:pos});
        },
        () => {
          ipFunction();
        }
      );
    } else if (!qr && !navigator.geolocation) {
      ipFunction();
    }
  };
  const checkFunction = (pos) => {
    new window.google.maps.Geocoder()
      .geocode({ location: pos })
      .then((result) => {
        const { results } = result;
        let coord = {};
        results[0].address_components.forEach((el) => {
          let field = el.types[0];
          coord[field] = el.short_name;
        });
        if (coord.administrative_area_level_1) {
          dictionaries.forEach((el) => {
            if (el.alterTitle === coord.administrative_area_level_1) {
              regionIdData = el.code;
            }
          });
        } else if (coord.administrative_area_level_2) {
          dictionaries.forEach((el) => {
            if (el.alterTitle === coord.administrative_area_level_2) {
              regionIdData = el.code;
            }
          });
        }
        setReady(true);
        // sessionStorage.setItem('coordinates',JSON.stringify(pos))
      })
  };
  const ipFunction = () => {
    if(!ready){
      axios
      .get("https://ipinfo.io/json?token=6de5360645668e")
      .then((resp) => {
        const pos = {
          lat: +(+resp.data.loc.split(",")[0]).toFixed(7),
          lng: +(+resp.data.loc.split(",")[1]).toFixed(7),
        };
        checkFunction(pos);
        setReady(true);
      })
      .catch((e) => {
        const pos = {
          lat: 50.4393067,
          lng: 30.5479288,
        };
        checkFunction(pos)
        setReady(true);
      });
    }
  
  };

  const chooseType = () => {
    if (!choose) {
      setChoose(true);
    } else {
      setChoose(false);
    }
  };

  const clickHandler = (event) => {
    event.preventDefault();
    if (
      (event.currentTarget.id && !qr) ||
      (qr && event.currentTarget.id == categoryId)
    ) {
      setCateg(event.currentTarget.id);
      setAction("category");
    }
  };
  const subcategoryClick = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (
      event &&
      event.currentTarget.id &&
      event.currentTarget.id.length === 3
    ) {
      setSub(event.currentTarget.id);
      setAction("difficulty");
    } else {
      setSub("");
      setAction("difficulty");
    }
  };

  const setDifClick = (e) => {
    let difficult = "";
    if (e.currentTarget.accessKey) {
      setDif(e.currentTarget.accessKey);
      difficult = e.currentTarget.accessKey;
    }
    let data={
      regionId:regionIdData,
      idgromady:idgromady,
    }
    if(currentUser && currentUser.id){
      data.userId= currentUser.id;
    }else{
      data.userId='';
    }
    dispatch(qrReadVideo(data))
      .then((res) => {
        if (type == "official") {
          navigate(
            `/video/${regionIdData}/${categ}/${difficult}/${sub ? sub : "0"}/0`
          );
        } else if (type == "commercial") {
          navigate(
            `/videouser/${regionIdData}/${categ}/${difficult}/${sub ? sub : "0"}/0`
          );
        } else {
          navigate(
            `/video/${regionIdData}/${categ}/${difficult}/${sub ? sub : "0"}/0/1`
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const clickEventHandler = (e) => {
    navigate(`/mainpage/${e.currentTarget.id}`)
    setType(e.currentTarget.id);
  };

  const backClick = (event) => {
    event.preventDefault();
    if (action === "category") {
      setAction("default");
      setCateg(event.currentTarget.id);
    } else if (action === "difficulty" && sub) {
      setAction("category");
    } else {
      setAction("default");
      setSub("");
    }
  };

  useEffect(() => {
    if (!ready) {
      checkGeolocation();
    }
  }, []);

  if (
    !ready ||
    !fullList ||
    !fullList?.ExerciseCategories ||
    !fullList?.ExerciseCategories.length > 0
  ) {
    return (
      <div style={{ position: "relative" }}>
        {" "}
        <div style={{ position: "absolute", top: "50%", left: "50%" }}>
          <Loader />
        </div>{" "}
      </div>
    );
  }

  if (
    ready &&
    fullList &&
    fullList?.ExerciseCategories &&
    fullList?.ExerciseCategories.length > 0
  ) {
    return (
      <>
      <div className="petalsPageHeaderBlock">
            <h1>Рухові активності</h1>
            <h4>В рамках програми Президента «Здорова Україна»</h4>
      </div>
      <div className="petalStartPageBlock">
                <div className="petalLeft1 petalLeft">
                  <button
              className="primaryLargeButtonIconTextDefault cusTom"
                    onClick={() => {
                      navigate(`/video/${regionIdData}/07/04/0/0`);
                    }}
                  >
                    <div>
                      <svg>
                        <use href="/images/icon24Rocket.svg#icon24" />
                      </svg>
                    </div>
                    Розминка
                  </button>
                  <button
                    className="primaryLargeButtonIconTextDefault cusTom"
                    onClick={() => {
                      navigate(`/video/${regionIdData}/08/04/0/0`);
                    }}
                  >
                    <div>
                      <svg>
                        <use href="/images/icon24Power.svg#icon24" />
                      </svg>
                    </div>
                    Заминка
                  </button>
                  <button
                    className="primaryLargeButtonIconTextDefault cusTom"
                    onClick={() => {
                      navigate(`/video/${regionIdData}/09/04/0/0`);
                    }}
                  >
                    <div>
                      <svg>
                        <use href="/images/icon24Medical.svg#icon24" />
                      </svg>
                    </div>
                    Поради лікаря
                  </button>
                </div>

                <PetalsBlock
                    action={action}
                    fullList={fullList}
                    category={categ}
                    difficulty={dif}
                    categoryId={categoryId}
                    className={className}
                    setClassName={setClassName}
                    backClick={backClick}
                    clickHandler={clickHandler}
                    setDifClick={setDifClick}
                    subcategoryClick={subcategoryClick}
                  ></PetalsBlock>
              <div className="justifyFlex">
                <div className="petalLeft2 petalLeft">
                  <button
              className="primaryLargeButtonIconTextDefault cusTom"
                    onClick={() => {
                      navigate(`/video/${regionIdData}/07/04/0/0`);
                    }}
                  >
                    <div>
                      <svg>
                        <use href="/images/icon24Rocket.svg#icon24" />
                      </svg>
                    </div>
                    Розминка
                  </button>
                  <button
                    className="primaryLargeButtonIconTextDefault cusTom"
                    onClick={() => {
                      navigate(`/video/${regionIdData}/08/04/0/0`);
                    }}
                  >
                    <div>
                      <svg>
                        <use href="/images/icon24Power.svg#icon24" />
                      </svg>
                    </div>
                    Заминка
                  </button>
                  <button
                    className="primaryLargeButtonIconTextDefault cusTom"
                    onClick={() => {
                      navigate(`/video/${regionIdData}/09/04/0/0`);
                    }}
                  >
                    <div>
                      <svg>
                        <use href="/images/icon24Medical.svg#icon24" />
                      </svg>
                    </div>
                    Поради лікаря
                  </button>
                </div>
                  
                <div className="petalLeft">
                  <button
                  className={videoType=='official'?"primaryLargeButtonIconTextDefault cus cusTom choosed":"primaryLargeButtonIconTextDefault cus cusTom"}
                  id="official"
                  onClick={clickEventHandler}
                  >
                    <div>
                     <Icon type='filmStrip'></Icon>
                    </div>
                    Офіційні відео
                  </button>
                  <button
                  className={videoType=='commercial'?"primaryLargeButtonIconTextDefault cus cusTom choosed":"primaryLargeButtonIconTextDefault cus cusTom"}
                  id="commercial"
                    onClick={clickEventHandler}
                  >
                    <div>
                     <Icon type='usersThree'></Icon>
                    </div>
                    Відео користувачів
                  </button>
                  <button
                    className={videoType=='tro'?"primaryLargeButtonIconTextDefault cus cusTom choosed":"primaryLargeButtonIconTextDefault cus cusTom"}
                    id="tro"
                    onClick={clickEventHandler}
                  >
                     <div>
                     <Icon type='sword'></Icon>
                    </div>
                    Адаптивні
                  </button>
                </div>
              </div>
            </div>
          </>    
    );
  }
};

export default MainPage;

import React, { useEffect, useState, useCallback } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import FileUploadAndCrop from "../../components/FileUploadAndCrop/FileUploadAndCrop";
import InfoWindow from "../../components/InfoWindow/InfoWindow";
import Loader from "../../components/Loader/Loader";
import getDate from "../../helpers/getDate";
import useCurrentMediaType from "../../hooks/currentMediaType";
import { useResponceChecker } from "../../hooks/useResponceChecker";
import { addFc, deleteFc, editFc, getFcItem } from "../../redux/actions/fc";
import { uploadFile } from "../../redux/actions/file";
import Map from "./Map/Map";
import axios from "axios";



const FCEditPage = () => {
  const [ready, setReady] = useState(false);
  const dispatch = useDispatch();
  const currentId = useParams().id;
  const [form, setForm] = useState({});
  const [arrAll, setArrAll] = useState(form?.location ? [{location:form?.location}] : []);
  const navigate = useNavigate();
  const { listId } = useSelector((state) => state);
  const { fullList } = useSelector((state) => state);
  const { currentUser } = useSelector((state) => state.currentUser);
  const [hasRights,setHasRights]=useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertModal, setAlertModal] = useState(false);
  const [formRes, setFormRes] = useState(null);
  const [address, setAdress] = useState(null);

  

  let options={
    uploadType:'avatar',
    initialAspectRatio:16 / 10,
    aspectRatio: 16 / 10,
    autoCropArea:16 / 10,
    height:'176px',
    width:'176px',
    size:3,
    type:'logoUrl',
    dragAbleZone:false,
    disabled:form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
  }
  const {
    msg: errorMessage,
    errorFiled,
    isError,
    isServerError,
  } = useResponceChecker(formRes);

  console.log(
    "errorMessage:",
    errorMessage,
    "isError:",
    isError,
    "errorFiled: ",
    errorFiled,
    "isServerError:",
    isServerError
  );

  useEffect(() => {
    if (isServerError) {
      setAlertModal(true);
      setAlertMessage(errorMessage);
    }
  }, [isServerError]);

  const initialFunc = () => {
    dispatch(getFcItem({ id: currentId })).then((res) => {
      setForm({ ...res });
      setReady(true);
    });
  };
  const editClick = (e) => {
    let data = {};
    data.type = e.target.accessKey;
    data.id = currentId;
    dispatch(addFc(data)).then((res) => {
      navigate(`/fcEdit/${res?.id}`);
      setReady(false);
    });
  };
  const changeStatus = (e) => {
    let data = {};
    data.type = e.target.accessKey;
    data.id = currentId;

    if(data.type==='to-moderation' && (!form.name || !form.description||  !form.tagline || form.name.length<1 || form.tagline.length<1 || form.description.length<1) ){
      setAlertMessage('Перевірте заповненість "Назви", "Опису" та "Гасла" клуба');
      setAlertModal(true);
    }else{
      dispatch(addFc(data)).then((res) => {
        if(form.originalId && data.type==='publish'){
          navigate("/fcList");
        }else{
          initialFunc();
        }
      });
    }
  };
  const saveEdit = useCallback(
    (data) => {
      if(Array.isArray(data)){
        let dat =form;
        dat.location=data[0].location;
        dispatch(editFc(dat.location ? dat : form)).then((res) =>
        setFormRes(res.isFeild ? res : {})
        );
      }else{
        dispatch(editFc(data.logoUrl ? data : form)).then((res) =>
        setFormRes(res.isFeild ? res : {})
        );
      }
      
    },
    [form]
  );

  const getCurrentLocation = (lat, lon) => {
    return axios
      .get(
        `https://map.technodreams.biz/reverse?lat=${lat}&lon=${lon}&accept-language=ua&format=json`
      )
      .then((res) => {
        var address = ""
        let resource =res.data.address;
        if (resource.amenity?.length>0){
          address += `${resource.amenity}, `;
          }
        if (resource.city?.length>0){
        address += `${resource.city}`;
        }
        if (!resource.city&& resource.state?.length>0){
        address += `${resource.state}, `;
        }
        if (resource.town?.length>0){
        address += `${resource.town}`;
        }
        if (resource.village?.length>0){
          address += `${resource.village}`;
          }
      
        if (resource.road?.length>0){
        address += `, ${resource.road}`;
        }
        if (resource.building?.length>0){
          address += ` ${resource.building}`;
          }
  
        if (resource.house_number?.length>0){
        address += ` ${resource.house_number}`;
        }
        setAdress(address);
        setForm({...form,['address']:address});
        return;
      })
      .catch((e) => {
        console.log(e);
        setAdress(lat.toFixed(4) +' - '+ lon.toFixed(4));
        setForm({...form,['address']:lat.toFixed(4) +' - '+ lon.toFixed(4)});
        return;
      });
  };


  const changeHandler = (e) => {
    if(e.target.id==='phone' && e.target.value.length===0 ){
      setForm({ ...form, [e.target.id]: '+'});
    }else{
      setForm({ ...form, [e.target.id]: e.target.value });
    }

  };

  const deleteClick = (e) => {
    dispatch(deleteFc({ id: currentId }))
      .then((res) => {
        navigate("/fcList");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const formDescription = useMemo(() => {
    if (form.description === null || !form.description) return "";
    return form.description;
  }, [form.description]);


  const updateArr = (ar) => {
    getCurrentLocation(ar[0].location[0],ar[0].location[1])
    setArrAll(ar);
    saveEdit(ar);
  };

  useEffect(() => {
    if(currentUser.permissions){
      const isFound = currentUser.permissions?.some(str => str === 'clubActing-'+currentId);
      if(isFound){
        setHasRights(true);
      }
    }
  }, []);

  useEffect(() => {
    if (!ready) {
      initialFunc();
    }
  }, [ready]);

  if (
    !ready ||
    !listId ||
    !listId.list ||
    (listId.list && listId.list.length == 0)
  ) {
    return <Loader />;
  } else {
    return (
      <>
        {alertModal && (
          <InfoWindow
            setModal={setAlertModal}
            message={alertMessage}
            buttonMsg={isServerError && "Оновити"}
            setClick={() => window.location.reload()}
          ></InfoWindow>
        )}
        <h1>
          Картка клубу
          <div className="headerClubSub">
            Статус: <span>{listId.list[form.statusId]}</span>
            <span className="currentStatusDivider"></span>
            {form.originalId && (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/fcEdit/" + form.originalId);
                  setReady(false);
                }}
              >
                Сторінка початкового клубу
              </a>
            )}
          </div>
        </h1>
        <div className="menuFunctionLine">
          <div className="actionButtonsBlock">
            <button
              className="secondaryButtonIconTextDefault"
              onClick={() => navigate("/fcList")}
            >
              <svg>
                <use href="/images/icon24ArrowLeft.svg#icon24" />
              </svg>
              Назад
            </button>

            {(form.statusId === "6508a900-74e4-4fb2-9c9d-78acf998323f" || form.statusId ==='b7db65d3-3207-4e1d-b87d-f2ec25aeba23' )&& (
              <button
                className="primaryButtonIconTextDefault"
                accessKey="to-moderation"
                onClick={changeStatus}
              >
                <svg accessKey="to-moderation">
                  <use
                    accessKey="to-moderation"
                    href="/images/icon24CheckCircle.svg#icon24"
                  />
                </svg>
                На модерацію
              </button>
            )}

            {form.statusId === "6ec9fa45-a030-43a6-88b4-c761fdf25836" &&
              currentUser.roleId === "d379ecaa-fee7-48a4-84df-a176f47820e6" && (
                <button
                  className="primaryButtonIconTextDefault"
                  accessKey="publish"
                  onClick={changeStatus}
                >
                  <svg accessKey="publish">
                    <use
                      accessKey="publish"
                      href="/images/icon24CheckCircle.svg#icon24"
                    />
                  </svg>
                  Опублікувати
                </button>
              )}
            {form.statusId === "6ec9fa45-a030-43a6-88b4-c761fdf25836" &&
              (currentUser.roleId === "d379ecaa-fee7-48a4-84df-a176f47820e6" || 
                currentUser.id === form.createdBy.id|| hasRights)  && (
                <button
                  accessKey="to-draft"
                  className="secondaryButtonIconTextDefault"
                  onClick={changeStatus}
                >
                  <svg accessKey="to-draft">
                    <use
                      accessKey="to-draft"
                      href="/images/icon24ArrowReturn.svg#icon24"
                    />
                  </svg>
                  Зняти з публікації та редагувати
                </button>
              )}
            {(form.statusId === "e19aa583-d4c7-476c-8be7-37fa5f5710f6" ||
              form.statusId === "b7db65d3-3207-4e1d-b87d-f2ec25aeba23") &&
                (currentUser.roleId ===
                  "d379ecaa-fee7-48a4-84df-a176f47820e6" || hasRights ||
                  currentUser.id === form.createdBy.id) && (
                  <button
                    className="primaryButtonIconTextDefault"
                    accessKey="duplicate"
                    onClick={editClick}
                  >
                    <svg>
                      <use href="/images/icon24Edit.svg#icon24" />
                    </svg>
                    Редагувати
                  </button>
                )}

            {form.statusId === "e19aa583-d4c7-476c-8be7-37fa5f5710f6" &&
              (currentUser.roleId === "d379ecaa-fee7-48a4-84df-a176f47820e6" ||
                currentUser.id === form.createdBy.id) && (
                <button
                  accessKey="deactivate"
                  className="secondaryButtonIconTextDefault"
                  onClick={changeStatus}
                >
                  <svg accessKey="deactivate">
                    <use
                      accessKey="deactivate"
                      href="/images/icon24Trash.svg#icon24"
                    />
                  </svg>
                  Деактивувати
                </button>
              )}

            {form.statusId === "6508a900-74e4-4fb2-9c9d-78acf998323f" && (
              <button
                className="secondaryButtonIconTextDefault"
                onClick={deleteClick}
              >
                <svg>
                  <use href="/images/icon24Trash.svg#icon24" />
                </svg>
                Видалити
              </button>
            )}
          </div>
        </div>
        <div
          style={{ marginTop: "24px" }}
          className="pageContentHeaderDivider"
        />

        <h3 style={{ marginTop: "40px" }}>Назва і гасло клубу</h3>

        <div className="apFCSection">
          <div
            style={{ width: "486px" }}
            className={`apFCSectionItem ${
              form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
                ? "apInputText apDisabled"
                : `${form.name ? "apInputText apFilled" : "apInputText"}`
            }`}
          >
            <label htmlFor="name">Назва*</label>
            <input
              id="name"
              type="text"
              disabled={
                form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
              }
              value={form.name || ''}
              onChange={changeHandler}
              onBlur={saveEdit}
            />
          </div>

          <div
            style={{ margin: "0 24x", width: "486px" }}
            className={`apFCSectionItem ${
              form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
                ? "apInputText apDisabled"
                : `${form.tagline ? "apInputText apFilled" : "apInputText"}`
            }`}
          >
            <label htmlFor="tagline">Гасло клубу*</label>
            <input
              id="tagline"
              type="text"
              disabled={
                form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
              }
              value={form.tagline||''}
              onChange={changeHandler}
              onBlur={saveEdit}
            />
          </div>

          <div
            style={{ width: "180px" }}
            className={`apFCSectionItem date  apInputText
            ${
              ( form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f") ?
                "apDisabled" : (form.establishedAt && `apFilled`) || ""}
            ${(errorFiled === "establishedAt" && "apError") || ""}
            `}
          >
            <label htmlFor="establishedAt">Дата створення</label>
            <input
              id="establishedAt"
              type="date"
              lang="ua"
              disabled={
                form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
              }
              value={getDate(form.establishedAt, "yyyy/mm/dd", "-")|| ''}
              onChange={changeHandler}
              onBlur={saveEdit}
            />
            {errorFiled === "establishedAt" && <span>{errorMessage}</span>}
          </div>
        </div>

        <h3 style={{ marginTop: "20px" }}>Логотип та опис клубу</h3>

        <div className="apFCSection">
          <div className="apFCSectionItem apFcSectionSmItem">
            {/* <div className=" videoCardCoverImageAndDrop">
              <div className="videoCardDropAreaAndHint">
                {form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f" ? (
                  <></>
                ) : (
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    id="logoUrl"
                    disabled={
                      form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
                    }
                    onChange={
                      form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
                        ? ""
                        : inputPut
                    }
                    style={{ display: "none" }}
                  />
                )}
                <label
                  id="drop_zone"
                  htmlFor="logoUrl"
                  className={`${form.logoUrl ? "withBg" : ""} ${
                    form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
                      ? "videoCardDropArea apDisabled"
                      : "videoCardDropArea"
                  }`}
                  style={{
                    backgroundImage: `${
                      form.logoUrl ? `url(${form.logoUrl})` : "none"
                    }`,
                  }}
                  onDrop={
                    form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
                      ? ""
                      : DropOn
                  }
                  onDragOver={
                    form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
                      ? ""
                      : dragOverHandler
                  }
                >
                  <div className="dropLabelText">
                    <svg>
                      <use href="/images/icon24Download.svg#icon24" />
                    </svg>
                    <span>
                      {form.statusId === "6508a900-74e4-4fb2-9c9d-78acf998323f"
                        ? `Перетягніть файли сюди або натисніть, щоб обрати.`
                        : "Опублікований клуб не можливо редагувати"}
                    </span>
                  </div>
                </label>

                <div className="apParkImagesHintArea">
                  <span>
                    Завантажуйте файл jpg, jpeg, png до&nbsp;10&nbsp;мегабайт.
                  </span>
                  <svg>
                    <use href="/images/icon24Info.svg#icon24" />
                  </svg>
                </div>
              </div>
            </div> */}
            <FileUploadAndCrop options={options} saveEdit={saveEdit} form={form} setForm={setForm} image={form.logoUrl} setAlertMessage={setAlertMessage} setAlertModal={setAlertModal}> </FileUploadAndCrop>
          </div>
          <div className="apFCSectionItem apFcSectionSmItem">
            <div
              className={`apInputTextIcon
                ${
                  form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f" ?
                    "apDisabled" :
                    (form.phone && `apFilled`) || ""
                } 
                ${(errorFiled === "phone" && "apError") || ""}
                `}
            >
              <label htmlFor="phone">Контактний телефон</label>
              <div>
                <input
                  id="phone"
                  type="text"
                  value={ form.phone || currentUser.phone}
                  onBlur={saveEdit}
                  disabled={
                    form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
                  }
                  onChange={changeHandler}
                />
                <svg>
                  <use href="/images/icon24Edit.svg#icon24" />
                </svg>
              </div>
              {errorFiled === "phone" && <span>{errorMessage}</span>}
            </div>
            <div
              className={`apInputTextIcon
              ${
                (form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f" &&
                  "apDisabled") ||
                ""
              } 
              ${(form.facebookUrl && `apFilled`) || ""} 
              ${(errorFiled === "facebookUrl" && "apError") || ""}
              `}
            >
              <label htmlFor="facebookUrl">Facebook</label>
              <div>
                <input
                  id="facebookUrl"
                  type="text"
                  onBlur={saveEdit}
                  disabled={
                    form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
                  }
                  onChange={changeHandler}
                  value={form.facebookUrl|| ''}
                />
                <svg style={{fill:'white'}}>
                  <use href="/images/facebook.svg#icon24" />
                </svg>
              </div>
              {errorFiled === "facebookUrl" && <span>{errorMessage}</span>}
            </div>
            <div
              className={`apInputTextIcon
              ${
                ( form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f" &&
                  "apDisabled") ||
                ""
              } 
              ${(form.instagramUrl && `apFilled`) || ""} 
              ${(errorFiled === "instagramUrl" && "apError") || ""}
              `}
            >
              <label htmlFor="instagramUrl">Instagram</label>
              <div>
                <input
                  id="instagramUrl"
                  type="text"
                  value={form.instagramUrl|| ''}
                  onChange={changeHandler}
                  onBlur={saveEdit}
                  disabled={
                    form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
                  }
                />
                <svg style={{fill:'white'}}>
                  <use href="/images/instagram.svg#icon24" />
                </svg>
              </div>
              {errorFiled === "instagramUrl" && <span>{errorMessage}</span>}
            </div>
            <div
              className={`apInputTextIcon
              ${
                (form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f" &&
                  "apDisabled") ||
                ""
              } 
              ${(form.youtubeUrl && `apFilled`) || ""} 
              ${(errorFiled === "youtubeUrl" && "apError") || ""}
              `}
            >
              <label htmlFor="youtubeUrl">YouTube</label>
              <div>
                <input
                  id="youtubeUrl"
                  onBlur={saveEdit}
                  disabled={
                    form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
                  }
                  onChange={changeHandler}
                  type="text"
                  value={form.youtubeUrl|| ''}
                />
                <svg>
                  <use href="/images/youtube.svg#icon24" />
                </svg>
              </div>
              {errorFiled === "youtubeUrl" && <span>{errorMessage}</span>}

            </div>
            <div
              className={`apInputTextIcon
              ${
                (form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f" &&
                  "apDisabled") ||
                ""
              } 
              ${(form.telegramUrl && `apFilled`) || ""} 
              ${(errorFiled === "telegramUrl" && "apError") || ""}
              `}
            >
              <label htmlFor="telegramUrl">Telegram</label>
              <div>
                <input
                  id="telegramUrl"
                  onBlur={saveEdit}
                  disabled={
                    form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
                  }
                  onChange={changeHandler}
                  type="text"
                  value={form.telegramUrl|| ''}
                />
                <svg>
                  <use href="/images/telegram.svg#icon24" />
                </svg>
              </div>
              {errorFiled === "telegramUrl" && <span>{errorMessage}</span>}
            </div>
          </div>
          <div className="apFCSectionItem apFcSectionLgItem">
            <div
              style={{ width: "588px" }}
              className={
                form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323"
                  ? "apTextarea apDisabled"
                  : `${form.description ? "apTextarea apFilled" : "apTextarea"}`
              }
            >
              <label htmlFor="description">Опис*</label>
              <textarea
                id="description"
                disabled={
                  form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"
                }
                maxLength="2000"
                value={formDescription|| ''}
                onChange={changeHandler}
                onBlur={saveEdit}
              />
              <div>
                <span />
                <span>
                  {form.description ? form.description.length : 0}/2000
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="apFCSection">
          <Map arrAll={arrAll} disabled={form.statusId !== "6508a900-74e4-4fb2-9c9d-78acf998323f"} updateArr={updateArr}></Map>
          <div
              className={
                 "apInputText"
              }
              style={{width:'100%'}}
            >
              <label htmlFor="title">Адреса</label>
              <input
                id="title"
                type="text"
                disabled={
                 true
                }
                value={address}
                placeholder="Оберіть адресу на карті"
              />
            </div>
        </div>
      </>
    );
  }
};

export default FCEditPage;

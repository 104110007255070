import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { codedia, loginNew } from "../../redux/actions/auth";
import FacebookProvider from "../../components/IdentityProvider/FacebookProvider/FacebookProvider";
import GoogleProvider from "../../components/IdentityProvider/GoogleProvider/GoogleProvider";
import Loader from "../../components/Loader/Loader";
import DiaProvider from "../../components/IdentityProvider/DiaProvider/DiaProvider";




const LoginPage = () => {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [ready,setReady]=useState(true);
  const [form, setForm] = useState({
    initialState: {
      email: "",
      password: "",
    },
  });
  const authResult = window.location.search;
  if (authResult.split("=")[1]) {
    const backUrl = authResult.split("=")[1];
    sessionStorage.setItem("backUrl", backUrl);
  }

  const [err, setErr] = useState(false);
  if (document.getElementsByClassName("sidebar")[0]) {
    document.getElementsByClassName("sidebar")[0].hidden = false;
  }
  function handleRegister() {
    navigate("/registration");
  }

  function handleRestore() {
    navigate("/restore");
  }
  const [_, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const signHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    let data={
      phoneLogin: form.email,
      password: form.password,
      typeId: 0
    }
    dispatch(loginNew(data))
      .then((res) => {
        if (res.error) {
          setErr(true);
        } else {
          if (sessionStorage.getItem("qr")) {
            navigate(`${sessionStorage.getItem("qr")}`);
          } else if(sessionStorage.getItem("link")){
            let url = sessionStorage.getItem("link")
            navigate(`${url}`);
            window.location.reload();
          }
          else {
            navigate("/");
            window.location.reload();
          }
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const kepLog = () => {
    let state = localStorage.getItem('state');
    let search = window.location.search.split('&');
    let value = search[1].slice(6, search[1].length);
    let codeData = search[0].slice(6, search[0].length);
    if (state == value) {
      let data = { code: codeData };
      console.log(data);
      dispatch(codedia({ ...data }))
        .then((res) => {
          if (typeof res === 'string') {
            // setErrKep(true);
          } else {
            navigate('/start');
          }
          // localStorage.removeItem('state');
          window.location.reload();
          setReady(true);
        })
        .catch((e) => {
          // setErrKep(true);
          // localStorage.removeItem('state');
          setReady(true);
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (window.location.search) {
      setReady(false);
      kepLog();
    }
  }, []);
  if (isLoggedIn) {
    // navigate("/applications")
  }
  if(ready){
    return (
      <>
      
      <div className="authContainer">
        <form className="authForm">
          <h1 className="">
            Вітаємо!
            <h4>З поверненням</h4>
          </h1>
          <div className={err ? "apInputText apError" : "apInputText"}>
            <label htmlFor="email">Пошта, телефон або нікнейм</label>
            <input
              id="email"
              type="text"
              name="email"
              onChange={changeHandler}
              value={form.email}
            />
            {err && <span className="">Неправильні дані</span>}
          </div>
          <div className={err ? "apInputTextIcon apError" : "apInputTextIcon"}>
            <label htmlFor="password">Пароль</label>
            <div>
              <input
                id="password"
                name="password"
                type={passwordShown ? "text" : "password"}
                onChange={changeHandler}
                value={form.password}
              />
              <svg onClick={togglePasswordVisiblity}>
                <use href="img/icon24MsgCircleDef.svg#icon24" />
              </svg>
            </div>
            {err && <span className="">Неправильні дані</span>}
          </div>

          <button
            className="primaryButtonTextDefault"
            type="submit"
            disabled={
              !form.password ||
              !form.email ||
              (form.password && form.password.length === 0) ||
              (form.email && form.email.length === 0)
            }
            onClick={signHandler}
          >
            Увійти
          </button>

          <div className="loginSeparator">
          або увійти за допомогою
          </div>

          <div className="buttonBlock">
            {/* <FacebookProvider setReady={setReady}/> */}
            <GoogleProvider setReady={setReady}/>
            {/* <DiaProvider  setReady={setReady}/> */}
          </div>

          <span>
            Не маєте акаунту?
            <a className="" onClick={handleRegister}>
              {" "}
              Зареєструватись
            </a>
          </span>
          <span>
            Забули пароль?
            <a className="" onClick={handleRestore}>
              {" "}
              Відновити пароль
            </a>
          </span>
        </form>
        <div className="rightSide">
          <img loading="lazy" src="images/LoginImage.svg" alt="LoginImage Not Found" />
          <div>ЗДОРОВА&nbsp;УКРАЇНА</div>
          <div>
            <span>ПРОГРАМА&nbsp;ПРЕЗИДЕНТА&nbsp;УКРАЇНИ</span>
          </div>
        </div>
      </div>
      </>
    );
  }else{
    <Loader/>
  }
};

export default LoginPage;
